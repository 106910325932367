import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentRefreshMethod: props.refreshMethod
    };
  }

  submit = async event => {
    event.preventDefault();

    const formData = new FormData(event.target);
    let formError = false;
    let fullName = formData.get("fullName");

    if (fullName === null || fullName === "") {
      formError = true;
    }

    if (formData.get("iUnderstand") === null) {
      formError = true;
    }

    if (formData.get("iAgreeSubscription") === null) {
      formError = true;
    }

    if (formData.get("iTermsAndConditions") === null) {
      formError = true;
    }

    if (formError) {
      alert("Please fill out the Name on the card as well as check all boxes.");
    } else {
      let { token } = await this.props.stripe.createToken({ name: fullName });
      if (token) {
        let bodyContent = {
          processSubscription: true,
          token: token.id,
          fullName: fullName
        };
        bodyContent["stripe"] = true;

        await fetch("api/stripepayments.php", {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify(bodyContent)
        });

        this.state.parentRefreshMethod();
      }
    }
  };

  reload = () => {
    this.accountForm.reset();
  };

  render() {
    return (
      <div key="AccountForm" className="threeQuartersWidth">
        <form
          onSubmit={this.submit}
          ref={function(node) {
            this.accountForm = node;
          }.bind(this)}
        >
          <table>
            <tbody>
              <tr>
                <td className="smallCell nowrap" colSpan="2">
                  Name on card:
                </td>
                <td className="smallCell nowrap">
                  <label className="shownError">*</label>
                </td>
                <td className="smallCell nowrap">
                  <input name="fullName" type="text" />
                </td>
                <td className="fullWidth" />
              </tr>
              <tr>
                <td colSpan="4">
                  <CardElement />
                </td>
              </tr>
              <tr>
                <td className="smallCell alignTop">
                  <label htmlFor="iUnderstand" className="shownError">
                    *
                  </label>
                </td>
                <td colSpan="4">
                  <input type="checkbox" name="iUnderstand" />
                  <label htmlFor="iUnderstand">
                    I understand that Eric's Gear doesn't store my credit card
                    information, except for the last 4 digits of the card and
                    expiration date (for card identification purposes). Stripe
                    handles all processing and card storage.
                  </label>
                </td>
              </tr>
              <tr>
                <td className="alignTop">
                  <label htmlFor="iAgreeSubscription" className="shownError">
                    *
                  </label>
                </td>
                <td colSpan="4">
                  <input type="checkbox" name="iAgreeSubscription" />
                  <label htmlFor="iAgreeSubscription">
                    I agree to pay Eric's Gear a fee of $10 per month as a
                    subscription to the "Scoreboard - EG" software suite
                    (including the online site and mobile app) until I cancel my
                    subscription or account.
                  </label>
                </td>
              </tr>
              <tr>
                <td className="alignTop">
                  <label htmlFor="iTermsAndConditions" className="shownError">
                    *
                  </label>
                </td>
                <td colSpan="4">
                  <input type="checkbox" name="iTermsAndConditions" />
                  <label htmlFor="iAgreeSubscription">
                    I have read and agree to the{" "}
                    <a href="./tac.html" target="_blank">
                      Terms and Conditions of Use
                    </a>{" "}
                    for this software suite and service.
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <input type="submit" value="Subscribe" />
                  <input
                    type="button"
                    value="Clear Form"
                    onClick={() => this.reload}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}

export default injectStripe(AccountForm);
